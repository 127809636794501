@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,300;0,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,300;0,500;1,700&family=Montserrat:wght@400;500;600;700&display=swap");

body{
  text-transform: uppercase;
}
input[type="text"]:focus,
input[type="time"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus {
  border: 1px solid #d0a34c;
  outline: none;
}
textarea {
  resize: none;
}
.css-13xfq8m-MuiTabPanel-root {
  @apply p-0 !important;
}

.sm-container .card-wrapper .MuiTableContainer-root {
  height: auto;
  max-height:1000px!important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d0a34c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #d0a34c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d0a34c;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  top: 1px;
  left: 1px;
}

.table-column:hover {
  background-color: #fffaf7 !important;
}
.td-data-not-found {
  @apply text-center !important;
}

/** scroll bar**/
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-button {
  background: #fff;
}
::-webkit-scrollbar-track-piece {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

button:disabled,
button[disabled] {
  background-color: #ffedc2;
  cursor: progress;
}
.ajs-ok {
  @apply bg-primary-color text-white-color font-karla rounded;
}
.ajs-cancel {
  @apply bg-text-light font-karla text-white-color rounded;
}
.ajs-content {
  @apply text-text-dark font-karla;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  padding: 30px !important;
}


.css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
  padding: 17px 0 !important;
  font-weight: 700 !important;
}
/**common content - css**/
/**Login Page**/
.username-field,
.password-field {
  @apply p-1.5 flex justify-between rounded gap-1  border-[1px] border-primary;
}

/**List page**/
.css-1ex1afd-MuiTableCell-root {
  @apply p-0 !important;
}
.css-1fw5qro-MuiPaper-root {
  @apply rounded-none !important;
}
.sm-container {
  @apply p-[24px] h-[99%] mb-[4px] overflow-y-scroll;
}
.heading-text {
  @apply text-xl font-bold	font-montserrat mt-[16px] mb-[16px] text-text-dark;
}
.card-wrapper {
  @apply shadow-sm rounded bg-white-color;
}
.card-header {
  @apply text-text-dark text-base font-semibold pt-[12px] pb-[12px] pl-[16px] pr-[16px] border-b-[1px] border-b-[#D9D9D9] font-montserrat;
}

.bread-crumbs {
  @apply flex justify-end text-text-dark text-sm font-semibold pb-[12px] font-montserrat;
}
.bread-crumbs .active {
  @apply text-text-dark cursor-default;
}
.bread-crumbs .in-active {
  @apply cursor-pointer text-text-light;
}
.search-block {
  @apply flex justify-between items-center pt-[24px] pb-[24px] pl-[24px] pr-[24px] gap-8;
}
.search {
  @apply p-1.5 flex justify-between rounded gap-1 w-[30%] border-[1px] border-primary;
}
.search-input-box {
  @apply font-karla text-sm w-[100%];
}
.input-block {
  @apply flex flex-col gap-2 w-[30%] min-h-[100px];
}
.input-block-allowance {
  @apply flex flex-col gap-2 w-[30%] min-h-[76px] w-full;
}
.input-field {
  @apply p-1.5 border-[1px]  border-primary;
}
.add-new-button-block {
  @apply bg-primary-color rounded whitespace-nowrap;
}
.add-new-button-container {
  @apply flex flex-row gap-2 w-[30%];
}
.add-new-input-label {
  @apply font-karla text-base text-text-dark font-medium;
}
.add-new-button {
  @apply px-3 py-2 font-karla flex gap-2 justify-center items-center text-[#fff];
}

.button-label {
  @apply font-montserrat font-medium text-base;
}
.add-new-button-block:hover {
  @apply bg-primary-btn-hvr;
}
.add-list-container {
  @apply flex justify-between items-start px-6 pb-[12px];
}
.table-header {
  @apply bg-table-header-clr text-text-dark font-montserrat font-semibold text-sm p-3 !important;
}
.attendance-header {
  @apply bg-table-header-clr !important;
}
.table-body {
  @apply pt-[8px] pb-[8px] pl-[16px] pr-[16px] text-sm font-normal font-karla text-text-light !important;
}
.loan-advance-expence-table {
  font-weight: 900 !important;
  font-size: 1rem !important;
}
.MuiStack-root {
  @apply p-2;
}
.MuiTablePagination-selectLabel {
  @apply font-karla text-xs text-text-dark !important;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  @apply font-karla text-xs text-text-dark !important;
}
.MuiButtonBase-root {
  @apply font-karla text-xs !important;
}
#KeyboardArrowLeftIcon {
  background-color: red;
}
.MuiPagination-ul li button:hover {
  @apply bg-clr-hover;
}

/**create Page**/
.view-list-button {
  @apply px-3 py-2 font-karla rounded flex gap-2 justify-end bg-secondary-color text-text-dark;
}
.form {
  @apply p-6 grid grid-cols-3 gap-y-1;
}
.form-full {
  @apply p-6 grid grid-cols-1 gap-y-1;
}
.field-block {
  @apply flex flex-col gap-1 min-h-[85px] w-[300px];
}
.field-block-full {
  @apply flex flex-col gap-1;
}
.label-name {
  @apply text-text-dark text-sm font-medium font-karla;
}
.required-symbol {
  @apply font-bold text-[#FF5C42];
}
.input-field {
  @apply p-2 border-solid border-[1px] border-primary rounded font-karla text-sm;
}
.validation-error-label {
  @apply text-[#FF5C42] text-sm font-medium font-karla;
}
.submit-reset-button {
  @apply flex justify-end pl-[24px] pr-[24px] pt-[14px] pb-[14px] gap-2;
}
.reset-button {
  @apply px-4 py-1 rounded flex gap-1 justify-center items-center border-[2px] border-primary;
}
.cancel-button {
  @apply px-4 py-2 rounded flex gap-1 justify-center items-center border-[2px] border-primary;
}
.submit-button {
  @apply px-4 py-2 rounded flex gap-1 justify-center items-center bg-primary-color;
}
.reset-button-label {
  @apply text-sm font-medium font-montserrat;
}
.submit-button-label {
  @apply text-sm font-medium text-[#fff] font-montserrat;
}

/**Details Page**/
.edit-button {
  @apply px-3 py-1 font-karla rounded flex gap-2 justify-end items-center bg-white-color text-text-dark  border-[2px] border-primary;
}
.list-container {
  @apply pb-[32px] pt-[32px] pl-[24px] pr-[24px];
}
.list-content-block {
  @apply grid grid-cols-3 gap-9;
}
.list {
  @apply flex flex-col gap-1 w-[300px];
}
.list-full {
  @apply flex flex-col gap-1 w-[100%];
}
.list-label {
  @apply text-text-dark text-base font-medium font-karla;
}
.list-value {
  @apply text-[#7B7B7B] text-sm font-karla font-normal;
}

.advance-section {
  @apply flex gap-6;
}

.advance-section-list-value {
  @apply text-[#7B7B7B] text-sm font-karla font-normal leading-6 ml-1;
}
.export-btn {
  @apply flex gap-2 items-center cursor-pointer border-solid border-[2px] border-[#D0A34C] pt-[6px] pb-[6px] pl-[10px] pr-[10px] rounded bg-[#D0A34C];
}

.export-btn-text {
  @apply text-[#FFF] text-base font-medium font-montserrat;
}

/* advance details */
.advance-details-image {
  @apply px-6;
}

.advance-details-image-container {
  @apply flex;
}

/**Edit Page**/

/**Master Log - page*/

.log-content {
  @apply p-4 flex flex-col gap-2;
}
.content-label {
  @apply text-text-dark font-montserrat text-base;
}
.content-value {
  @apply text-text-light font-montserrat text-sm;
}
.log-field-block {
  @apply flex gap-2 justify-start items-center;
}
.log-table {
  @apply border-[1px] border-primary p-3;
}
.log-table-data {
  @apply p-3;
}
.log-icon:hover {
  @apply cursor-pointer;
}

.field-block-checkbox {
  @apply block flex-col gap-1 min-h-[85px];
}

.import-button {
  @apply font-karla text-[#FFF] text-lg justify-center items-center;
}
/**filter button*/
.filter-button {
  @apply flex justify-center items-center gap-1 cursor-pointer;
}
.filter-btn-label {
  @apply text-base font-medium font-montserrat;
}

.employee-profile-picture {
  @apply h-[35px] w-[35px] !important;
}

.MuiTabs-flexContainer button {
  @apply text-text-dark text-sm font-medium px-[14px] py-[12px]  !important;
}
.MuiTabs-indicator {
  @apply bg-primary-color !important;
}
.page-not-found {
  @apply flex justify-center items-center;
}

.report-card-block {
  @apply flex;
}

.report-card {
  @apply shadow-md rounded bg-[#fff] w-1/3 flex justify-center items-center p-3 m-3  border-[1px] border-primary rounded-md;
}

.report-card-title {
  @apply font-montserrat text-base whitespace-nowrap  text-text-dark font-medium;
}

.report-branch-name {
  @apply text-base font-medium font-montserrat mx-3;
}
.alertify .ajs-dialog {
  @apply max-w-[650px] !important;
}

.advance-voucher-block {
  @apply text-center;
}

.advance-voucher-title {
  @apply text-2xl font-semibold font-montserrat;
}

.advance-voucher-barcode-block {
  @apply text-right;
}

.advance-voucher-barcode {
  @apply h-14 inline;
}

.voucher-emp-code-name {
  @apply my-3 py-3.5;
}
.voucher-amount-block {
  @apply flex justify-end;
}

.voucher-amount {
  @apply w-36 text-base font-semibold font-montserrat px-1 py-1 ml-2;
}

.bank-voucher-border {
  @apply border-[1px] border-primary;
}

.advance-voucher-bold {
  @apply text-base font-bold font-karla;
}

.payslip-header {
  @apply text-text-dark text-lg font-semibold pt-[12px] pb-[12px] pl-[16px] pr-[16px] font-montserrat;
}
.payslip-pf-header {
  @apply text-text-dark text-lg font-semibold pt-[12px] pb-[12px] pl-[16px] pr-[16px] font-montserrat;
}

#scissors {
  height: 43px;
  width: 100%;
  background-image: url("./Assets/Icons/Scissor.svg");
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
}
#scissors:after {
  content: "";
  position: relative;
  top: 50%;
  display: block;
  border-top: 1px dashed #d9d9d9;
}
h3 {
  @apply text-text-dark text-lg;
}

.loandetails-input-block {
  @apply flex flex-col gap-2 w-[22%] min-h-[100px];
}

.loandetails-border {
  @apply border-t-[1px] border-primary;
}

/**report*/

.report-card-block {
  @apply flex justify-between items-center w-full gap-2  pb-[32px];
}
.card {
  @apply p-4 w-3/12 flex justify-between items-center bg-white-color rounded-lg shadow-md hover:scale-105 transition duration-500;
}
.content-left {
  @apply flex flex-col gap-2;
}
.content-left .card-value {
  @apply text-text-dark text-xl font-montserrat font-bold;
}
.content-left .card-label {
  @apply text-text-light text-sm font-medium font-montserrat;
}
/* .container{
  @apply max-w-0
} */

.slick-prev {
  @apply hidden !important;
}
.slick-next {
  @apply hidden !important;
}
.slick-dots {
  @apply bottom-0 !important;
}

.employee-break-details-border {
  @apply border-b-[1px] border-b-[#D9D9D9];
}

.employee-break-details {
  @apply text-text-dark text-lg font-semibold font-montserrat pt-[12px] pb-[12px] pl-[20px];
}

.create-emp .MuiInputBase-input {
  @apply p-2 border-solid border-[1px] border-primary rounded font-karla text-sm;
}

.input-field-date-picker .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity)) !important;
}

.food-allowance-total-amt {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.css-13cymwt-control:focus {
  border: 1px solid #d0a34c !important;
}

.css-t3ipsp-control:hover {
  border-color: #d0a34c !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
}

.dropdown-select-to-first .css-b62m3t-container {
  z-index: 1981;
}

.dropdown-select-to-second .css-b62m3t-container {
  z-index: 1000;
}

.dropdown-select-to-third .css-b62m3t-container {
  z-index: 500;
}
.dropdown-select-to-fourth .css-b62m3t-container {
  z-index: 300;
}

.css-1jqq78o-placeholder {
  @apply font-karla text-sm;
}

.css-1wf2bll-MuiStack-root {
  padding-top: 4px !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  @apply font-karla text-sm;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: center !important;
  height: 0.5em !important;
}

/* hide number field arrao marks */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.emp-allowance-error {
  font-size: 3.75rem !important;
}

.rejoin-employee-width {
  width: 100% !important;
}

/**home page**/

.home-page-logo{
  background-image: url('./Assets/Logo/Logo.png');
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.1;
}

/* purchase order */
.po-container {
  @apply px-6 pt-5 pb-5 bg-[#F7F6F4] grow overflow-y-scroll;
}
.po-header {
  @apply pb-4 font-montserrat font-semibold text-text-dark text-lg;
}
.po-contect-box {
  @apply px-6 pt-5 pb-5 bg-[#fff] rounded;
}
.po-contect-box .header-name {
  @apply font-montserrat font-semibold text-lg pb-5 border-b-[1px] border-b-[#D9D9D9];
}

.po-form {
  /* @apply pt-5 */
}
.po-form .form-block-1 {
  @apply grid grid-cols-3 gap-2 mt-5 mb-5;
}
.po-form .previous-pr {
  @apply grid grid-cols-4 gap-2 pt-5;
}
.po-form .input-field-block {
  @apply flex flex-col gap-1 min-w-[200px] min-h-[86px];
}
.po-form .form-block-2 {
  @apply border-[1px] border-[#DADADA] p-5 mt-5 mb-5  shadow-lg rounded;
}
.po-form .form-block-2 .header {
  @apply font-montserrat text-lg font-semibold text-text-dark  pb-5 border-b-[1px] border-b-[#D9D9D9];
}
.po-form .grid-block {
  @apply grid grid-cols-3 gap-2 pt-5 items-start;
}
.po-search {
  @apply p-1.5 flex justify-between rounded gap-1 border-[1px] border-primary;
}
.po-pr-details-content-block {
  @apply mt-5 p-5 border-[1px] border-[#DADADA]  shadow-lg rounded;
}
.details-header-block {
  @apply flex justify-between items-center;
}
.details-header {
  @apply font-montserrat font-semibold text-lg;
}
.pr-details-toggle {
  @apply grid grid-cols-2 gap-2 pt-5 pb-5 border-b-[1px] border-[#DADADA];
}
.pr-details-toggle .details-block {
  @apply flex gap-2;
}
.label {
  @apply font-montserrat text-sm font-semibold text-text-dark;
}
.value {
  @apply font-montserrat text-sm text-text-light font-semibold;
}
.pr-details-approved-card {
  @apply text-white-color font-karla font-semibold tracking-wide text-[8px] flex justify-center items-center p-0.5 rounded;
}
.pr-item-details-card {
  @apply p-5 items-center  border-[1px] border-[#DADADA] shadow-lg rounded mt-5 font-montserrat text-text-dark font-semibold text-base;
}

.pr-item-details-card-label {
  @apply flex justify-between cursor-pointer;
}
.pr-item-details-card .product-details {
  @apply grid grid-cols-3 mt-5 gap-8;
}

.footer-custom-text {
  @apply font-karla text-text-dark text-base font-semibold !important;
}

/* Branch wise report Table */
.branch-wise-report-table .MuiTableContainer-root::-webkit-scrollbar {
  height: 6px;
  width: 4px;
}

.branch-wise-report-table .MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

.branch-wise-report-table
  .MuiTableContainer-root::-webkit-scrollbar-track:hover {
  background-color: #cccccc;
}

.branch-wise-report-table
  .MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


